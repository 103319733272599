import {
  FC,
  useEffect,
  useRef,
  useState
} from 'react';
import { createPortal } from 'react-dom';

import type { ComponentProps } from './_Portal.types';


export const Portal: FC<ComponentProps> = ({
  selector,
  children
}) => {
  const [ mounted, setMounted ] = useState<boolean>(false);

  const ref = useRef<Element | null>(null);


  useEffect(() => {
    const target = document.querySelector(selector);

    if (target) {
      ref.current = target;

      setMounted(true);
    }
  }, [selector]);


  return mounted && ref.current ? createPortal(children, ref.current) : null;
};
