import { FC } from 'react';
import classNames from 'classnames';

import styles from './_Button.module.scss';

import type { ComponentProps } from './_Button.types';


export const Button: FC<ComponentProps> = ({
  children,
  className,
  modifiers = [ 'default' ],
  type = 'button',
  onClick,
  ...props
}) => {
  return <>
    <button
      className={classNames(
        className,
        styles.root,
        modifiers.map((modifier) => styles[`root_${ modifier }`])
      )}
      type={ type }
      onClick={ onClick }
      { ...props }
    >
      { children }
    </button>
  </>
};
