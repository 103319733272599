import {
  FC,
  useRef
} from 'react';
import classNames from 'classnames';

import styles from './_Modal.module.scss';

import type { ComponentProps } from './_Modal.types';

import {
  useOnClickOutside,
  useOnEscKeydown
} from 'hooks';

import { IconBack, IconCross } from 'icons';
import {
  Portal,
  Container
} from 'components';


export const Modal: FC<ComponentProps> = ({
  title,
  children,
  onClose,
  hasCloseButton,
  modifiers = [],
  hasBackBtn,
  topChildren,
  onBackBtnClick,
  onCloseEsc = onClose
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);


  useOnClickOutside(contentRef, onCloseEsc);
  useOnEscKeydown(onCloseEsc);


  return <>
    <Portal selector='#modal-root'>
      <div className={classNames(
        styles.root,
        modifiers.map((modifier) => styles[`root_${ modifier }`])
      )}>
        <Container className={ styles.container }>
          {topChildren && (
            <div className={ styles.top }>
              {topChildren}
            </div>
          )}
          <div
            ref={ contentRef }
            className={ styles.content }
          >
            {
              title && <>
                <h2 className={classNames(
                  styles.title,
                  hasCloseButton && styles.title_restricted
                )}>
                  { title }
                </h2>
              </>
            }

            {
              hasCloseButton && <>
                <button
                  className={ styles.closeButton }
                  onClick={ () => onClose() }
                  aria-label={ 'Закрыть модальное окно' }
                >
                  <IconCross/>
                </button>
              </>
            }

            {
              hasBackBtn && <>
                <button
                  className={ styles.backButton }
                  onClick={ onBackBtnClick }
                  aria-label={ 'Закрыть модальное окно' }
                >
                  <IconBack/>
                </button>
              </>
            }

            { children }
          </div>
        </Container>
      </div>
    </Portal>
  </>
};
