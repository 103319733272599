import {
  FC,
  useState
} from 'react';
import classNames from 'classnames';

import styles from './_Header.module.scss';

import type { ComponentProps } from './_Header.types';

import {
  IconQuestion,
  IconFonbet
} from 'icons';
import {
  Container,
  FindsCounter
} from 'components';
import { ModalAwards } from 'modals';


export const Header: FC<ComponentProps> = ({
  className
}) => {
  const [ isAwardsModalOpened, setisAwardsModalOpened ] = useState<boolean>(false);


  return <>
    <div className={ classNames(className, styles.root) }>
      <div className={ styles.item }>
        <Container className={ styles.itemContainer }>
          <button
            className={ styles.infoButton }
            type={ 'button' }
            aria-label={ 'Как играть?' }
            onClick={ () => setisAwardsModalOpened(true) }
          >
            <IconQuestion/>
          </button>

          <FindsCounter/>
        </Container>
      </div>

      <div className={ styles.item }>
        <Container className={ styles.itemContainer }>  
          <span
            className={ styles.logo }
            aria-label={ 'Fonbet' }
          >
            <IconFonbet/>
          </span>
        </Container>
      </div>
    </div>

    {
      isAwardsModalOpened && <>
        <ModalAwards onClose={() => setisAwardsModalOpened(false)} />
      </>
    }
  </>
};
