export const IconPhone = () => (
  <svg width="56" height="58" viewBox="0 0 56 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28 0.500003L30 0.500003L30 2.5L28 2.5L28 0.500003Z" fill="#0C0049" />
    <path d="M28 6.5L30 6.5L30 8.5L28 8.5L28 6.5Z" fill="#0C0049" />
    <path d="M24 32.5L24 30.5L26 30.5L26 32.5L24 32.5Z" fill="#0C0049" />
    <path d="M54 32.5L54 30.5L56 30.5L56 32.5L54 32.5Z" fill="#0C0049" />
    <path d="M50 56.5L50 54.5L52 54.5L52 56.5L50 56.5Z" fill="#0C0049" />
    <path d="M26 54.5L26 52.5L28 52.5L28 54.5L26 54.5Z" fill="#0C0049" />
    <path d="M50 47.5L48 47.5L48 45.5L50 45.5L50 47.5Z" fill="#0C0049" />
    <path d="M46 56.5L44 56.5L44 54.5L46 54.5L46 56.5Z" fill="#0C0049" />
    <path d="M52 47.5L50 47.5L50 45.5L52 45.5L52 47.5Z" fill="#0C0049" />
    <path d="M48 56.5L46 56.5L46 54.5L48 54.5L48 56.5Z" fill="#0C0049" />
    <path d="M54 47.5L52 47.5L52 45.5L54 45.5L54 47.5Z" fill="#0C0049" />
    <path d="M50 56.5L48 56.5L48 54.5L50 54.5L50 56.5Z" fill="#0C0049" />
    <path d="M36 0.500003L38 0.500003L38 2.5L36 2.5L36 0.500003Z" fill="#0C0049" />
    <path d="M36 6.5L38 6.5L38 8.5L36 8.5L36 6.5Z" fill="#0C0049" />
    <path d="M24 24.5L24 22.5L26 22.5L26 24.5L24 24.5Z" fill="#0C0049" />
    <path d="M54 24.5L54 22.5L56 22.5L56 24.5L54 24.5Z" fill="#0C0049" />
    <path d="M54 48.5L54 46.5L56 46.5L56 48.5L54 48.5Z" fill="#0C0049" />
    <path d="M24 48.5L24 46.5L26 46.5L26 48.5L24 48.5Z" fill="#0C0049" />
    <path d="M42 47.5L40 47.5L40 45.5L42 45.5L42 47.5Z" fill="#0C0049" />
    <path d="M38 56.5L36 56.5L36 54.5L38 54.5L38 56.5Z" fill="#0C0049" />
    <path d="M32 56.5L30 56.5L30 54.5L32 54.5L32 56.5Z" fill="#0C0049" />
    <path d="M44 0.500003L46 0.500003L46 2.5L44 2.5L44 0.500003Z" fill="#0C0049" />
    <path d="M44 6.5L46 6.5L46 8.5L44 8.5L44 6.5Z" fill="#0C0049" />
    <path d="M24 16.5L24 14.5L26 14.5L26 16.5L24 16.5Z" fill="#0C0049" />
    <path d="M54 16.5L54 14.5L56 14.5L56 16.5L54 16.5Z" fill="#0C0049" />
    <path d="M54 40.5L54 38.5L56 38.5L56 40.5L54 40.5Z" fill="#0C0049" />
    <path d="M24 40.5L24 38.5L26 38.5L26 40.5L24 40.5Z" fill="#0C0049" />
    <path d="M34 47.5L32 47.5L32 45.5L34 45.5L34 47.5Z" fill="#0C0049" />
    <path d="M30 0.500003L32 0.500003L32 2.5L30 2.5L30 0.500003Z" fill="#0C0049" />
    <path d="M30 6.5L32 6.5L32 8.5L30 8.5L30 6.5Z" fill="#0C0049" />
    <path d="M24 30.5L24 28.5L26 28.5L26 30.5L24 30.5Z" fill="#0C0049" />
    <path d="M54 30.5L54 28.5L56 28.5L56 30.5L54 30.5Z" fill="#0C0049" />
    <path d="M52 54.5L52 52.5L54 52.5L54 54.5L52 54.5Z" fill="#0C0049" />
    <path d="M28 56.5L28 54.5L30 54.5L30 56.5L28 56.5Z" fill="#0C0049" />
    <path d="M48 47.5L46 47.5L46 45.5L48 45.5L48 47.5Z" fill="#0C0049" />
    <path d="M44 56.5L42 56.5L42 54.5L44 54.5L44 56.5Z" fill="#0C0049" />
    <path d="M38 0.500003L40 0.500003L40 2.5L38 2.5L38 0.500003Z" fill="#0C0049" />
    <path d="M38 6.5L40 6.5L40 8.5L38 8.5L38 6.5Z" fill="#0C0049" />
    <path d="M24 22.5L24 20.5L26 20.5L26 22.5L24 22.5Z" fill="#0C0049" />
    <path d="M54 22.5L54 20.5L56 20.5L56 22.5L54 22.5Z" fill="#0C0049" />
    <path d="M54 46.5L54 44.5L56 44.5L56 46.5L54 46.5Z" fill="#0C0049" />
    <path d="M24 46.5L24 44.5L26 44.5L26 46.5L24 46.5Z" fill="#0C0049" />
    <path d="M40 47.5L38 47.5L38 45.5L40 45.5L40 47.5Z" fill="#0C0049" />
    <path d="M46 0.500003L48 0.500003L48 2.5L46 2.5L46 0.500003Z" fill="#0C0049" />
    <path d="M46 6.5L48 6.5L48 8.5L46 8.5L46 6.5Z" fill="#0C0049" />
    <path d="M24 14.5L24 12.5L26 12.5L26 14.5L24 14.5Z" fill="#0C0049" />
    <path d="M54 14.5L54 12.5L56 12.5L56 14.5L54 14.5Z" fill="#0C0049" />
    <path d="M54 38.5L54 36.5L56 36.5L56 38.5L54 38.5Z" fill="#0C0049" />
    <path d="M24 38.5L24 36.5L26 36.5L26 38.5L24 38.5Z" fill="#0C0049" />
    <path d="M32 47.5L30 47.5L30 45.5L32 45.5L32 47.5Z" fill="#0C0049" />
    <path d="M32 0.500003L34 0.500003L34 2.5L32 2.5L32 0.500003Z" fill="#0C0049" />
    <path d="M32 6.5L34 6.5L34 8.5L32 8.5L32 6.5Z" fill="#0C0049" />
    <path d="M24 28.5L24 26.5L26 26.5L26 28.5L24 28.5Z" fill="#0C0049" />
    <path d="M54 28.5L54 26.5L56 26.5L56 28.5L54 28.5Z" fill="#0C0049" />
    <path d="M54 52.5L54 50.5L56 50.5L56 52.5L54 52.5Z" fill="#0C0049" />
    <path d="M24 52.5L24 50.5L26 50.5L26 52.5L24 52.5Z" fill="#0C0049" />
    <path d="M46 47.5L44 47.5L44 45.5L46 45.5L46 47.5Z" fill="#0C0049" />
    <path d="M42 56.5L40 56.5L40 54.5L42 54.5L42 56.5Z" fill="#0C0049" />
    <path d="M36 56.5L34 56.5L34 54.5L36 54.5L36 56.5Z" fill="#0C0049" />
    <path d="M42 52.5L39 52.5L39 49.5L42 49.5L42 52.5Z" fill="#0C0049" />
    <path d="M40 0.500003L42 0.500003L42 2.5L40 2.5L40 0.500003Z" fill="#0C0049" />
    <path d="M40 6.5L42 6.5L42 8.5L40 8.5L40 6.5Z" fill="#0C0049" />
    <path d="M24 20.5L24 18.5L26 18.5L26 20.5L24 20.5Z" fill="#0C0049" />
    <path d="M54 20.5L54 18.5L56 18.5L56 20.5L54 20.5Z" fill="#0C0049" />
    <path d="M54 44.5L54 42.5L56 42.5L56 44.5L54 44.5Z" fill="#0C0049" />
    <path d="M24 44.5L24 42.5L26 42.5L26 44.5L24 44.5Z" fill="#0C0049" />
    <path d="M38 47.5L36 47.5L36 45.5L38 45.5L38 47.5Z" fill="#0C0049" />
    <path d="M48 0.500003L50 0.500003L50 2.5L48 2.5L48 0.500003Z" fill="#0C0049" />
    <path d="M48 6.5L50 6.5L50 8.5L48 8.5L48 6.5Z" fill="#0C0049" />
    <path d="M24 12.5L24 10.5L26 10.5L26 12.5L24 12.5Z" fill="#0C0049" />
    <path d="M54 12.5L54 10.5L56 10.5L56 12.5L54 12.5Z" fill="#0C0049" />
    <path d="M54 36.5L54 34.5L56 34.5L56 36.5L54 36.5Z" fill="#0C0049" />
    <path d="M24 36.5L24 34.5L26 34.5L26 36.5L24 36.5Z" fill="#0C0049" />
    <path d="M30 47.5L28 47.5L28 45.5L30 45.5L30 47.5Z" fill="#0C0049" />
    <path d="M34 0.500003L36 0.500003L36 2.5L34 2.5L34 0.500003Z" fill="#0C0049" />
    <path d="M34 6.5L36 6.5L36 8.5L34 8.5L34 6.5Z" fill="#0C0049" />
    <path d="M24 26.5L24 24.5L26 24.5L26 26.5L24 26.5Z" fill="#0C0049" />
    <path d="M54 26.5L54 24.5L56 24.5L56 26.5L54 26.5Z" fill="#0C0049" />
    <path d="M54 50.5L54 48.5L56 48.5L56 50.5L54 50.5Z" fill="#0C0049" />
    <path d="M24 50.5L24 48.5L26 48.5L26 50.5L24 50.5Z" fill="#0C0049" />
    <path d="M44 47.5L42 47.5L42 45.5L44 45.5L44 47.5Z" fill="#0C0049" />
    <path d="M40 56.5L38 56.5L38 54.5L40 54.5L40 56.5Z" fill="#0C0049" />
    <path d="M34 56.5L32 56.5L32 54.5L34 54.5L34 56.5Z" fill="#0C0049" />
    <path d="M42 0.500003L44 0.500003L44 2.5L42 2.5L42 0.500003Z" fill="#0C0049" />
    <path d="M42 6.5L44 6.5L44 8.5L42 8.5L42 6.5Z" fill="#0C0049" />
    <path d="M24 18.5L24 16.5L26 16.5L26 18.5L24 18.5Z" fill="#0C0049" />
    <path d="M54 18.5L54 16.5L56 16.5L56 18.5L54 18.5Z" fill="#0C0049" />
    <path d="M54 42.5L54 40.5L56 40.5L56 42.5L54 42.5Z" fill="#0C0049" />
    <path d="M24 42.5L24 40.5L26 40.5L26 42.5L24 42.5Z" fill="#0C0049" />
    <path d="M36 47.5L34 47.5L34 45.5L36 45.5L36 47.5Z" fill="#0C0049" />
    <path d="M50 0.500003L52 0.500003L52 2.5L50 2.5L50 0.500003Z" fill="#0C0049" />
    <path d="M50 6.5L52 6.5L52 8.5L50 8.5L50 6.5Z" fill="#0C0049" />
    <path d="M24 10.5L24 8.5L26 8.5L26 10.5L24 10.5Z" fill="#0C0049" />
    <path d="M54 10.5L54 8.5L56 8.5L56 10.5L54 10.5Z" fill="#0C0049" />
    <path d="M54 34.5L54 32.5L56 32.5L56 34.5L54 34.5Z" fill="#0C0049" />
    <path d="M24 34.5L24 32.5L26 32.5L26 34.5L24 34.5Z" fill="#0C0049" />
    <path d="M28 47.5L26 47.5L26 45.5L28 45.5L28 47.5Z" fill="#0C0049" />
    <path d="M52 2.5L54 2.5L54 4.5L52 4.5L52 2.5Z" fill="#0C0049" />
    <path d="M26 2.5L28 2.5L28 4.5L26 4.5L26 2.5Z" fill="#0C0049" />
    <path d="M18 4.5L20 4.5L20 6.5L18 6.5L18 4.5Z" fill="#0C0049" />
    <path d="M16 4.5L18 4.5L18 6.5L16 6.5L16 4.5Z" fill="#0C0049" />
    <path d="M14 6.5L16 6.5L16 8.5L14 8.5L14 6.5Z" fill="#0C0049" />
    <path d="M12 6.5L14 6.5L14 8.5L12 8.5L12 6.5Z" fill="#0C0049" />
    <path d="M10 8.5L12 8.5L12 10.5L10 10.5L10 8.5Z" fill="#0C0049" />
    <path d="M8 10.5L10 10.5L10 12.5L8 12.5L8 10.5Z" fill="#0C0049" />
    <path d="M8 12.5L10 12.5L10 14.5L8 14.5L8 12.5Z" fill="#0C0049" />
    <path d="M6 14.5L8 14.5L8 16.5L6 16.5L6 14.5Z" fill="#0C0049" />
    <path d="M6 16.5L8 16.5L8 18.5L6 18.5L6 16.5Z" fill="#0C0049" />
    <path d="M6 18.5L8 18.5L8 20.5L6 20.5L6 18.5Z" fill="#0C0049" />
    <path d="M20 23.5L22 23.5L22 25.5L20 25.5L20 23.5Z" fill="#0C0049" />
    <path d="M-1.54988e-06 31.5L-1.52603e-06 29.5L2 29.5L2 31.5L-1.54988e-06 31.5Z" fill="#0C0049" />
    <path d="M6 29.5L6 27.5L8 27.5L8 29.5L6 29.5Z" fill="#0C0049" />
    <path d="M6 47.5L6 45.5L8 45.5L8 47.5L6 47.5Z" fill="#0C0049" />
    <path d="M14 23.5L16 23.5L16 25.5L14 25.5L14 23.5Z" fill="#0C0049" />
    <path d="M-1.62143e-06 37.5L-1.59758e-06 35.5L2 35.5L2 37.5L-1.62143e-06 37.5Z" fill="#0C0049" />
    <path d="M6 35.5L6 33.5L8 33.5L8 35.5L6 35.5Z" fill="#0C0049" />
    <path d="M6 53.5L6 51.5L8 51.5L8 53.5L6 53.5Z" fill="#0C0049" />
    <path d="M8 23.5L10 23.5L10 25.5L8 25.5L8 23.5Z" fill="#0C0049" />
    <path d="M-1.69298e-06 43.5L-1.66913e-06 41.5L2 41.5L2 43.5L-1.69298e-06 43.5Z" fill="#0C0049" />
    <path d="M6 41.5L6 39.5L8 39.5L8 41.5L6 41.5Z" fill="#0C0049" />
    <path d="M-1.81223e-06 53.5L-1.78838e-06 51.5L2 51.5L2 53.5L-1.81223e-06 53.5Z" fill="#0C0049" />
    <path d="M-1.78838e-06 51.5L-1.76453e-06 49.5L2 49.5L2 51.5L-1.78838e-06 51.5Z" fill="#0C0049" />
    <path d="M-1.76453e-06 49.5L-1.74068e-06 47.5L2 47.5L2 49.5L-1.76453e-06 49.5Z" fill="#0C0049" />
    <path d="M18 23.5L20 23.5L20 25.5L18 25.5L18 23.5Z" fill="#0C0049" />
    <path d="M-1.57373e-06 33.5L-1.54988e-06 31.5L2 31.5L2 33.5L-1.57373e-06 33.5Z" fill="#0C0049" />
    <path d="M6 31.5L6 29.5L8 29.5L8 31.5L6 31.5Z" fill="#0C0049" />
    <path d="M6 49.5L6 47.5L8 47.5L8 49.5L6 49.5Z" fill="#0C0049" />
    <path d="M12 23.5L14 23.5L14 25.5L12 25.5L12 23.5Z" fill="#0C0049" />
    <path d="M-1.64528e-06 39.5L-1.62143e-06 37.5L2 37.5L2 39.5L-1.64528e-06 39.5Z" fill="#0C0049" />
    <path d="M6 37.5L6 35.5L8 35.5L8 37.5L6 37.5Z" fill="#0C0049" />
    <path d="M6 55.5L6 53.5L8 53.5L8 55.5L6 55.5Z" fill="#0C0049" />
    <path d="M6 23.5L8 23.5L8 25.5L6 25.5L6 23.5Z" fill="#0C0049" />
    <path d="M-1.71683e-06 45.5L-1.69298e-06 43.5L2 43.5L2 45.5L-1.71683e-06 45.5Z" fill="#0C0049" />
    <path d="M6 43.5L6 41.5L8 41.5L8 43.5L6 43.5Z" fill="#0C0049" />
    <path d="M2 55.5L2 53.5L4 53.5L4 55.5L2 55.5Z" fill="#0C0049" />
    <path d="M16 23.5L18 23.5L18 25.5L16 25.5L16 23.5Z" fill="#0C0049" />
    <path d="M-1.59758e-06 35.5L-1.57373e-06 33.5L2 33.5L2 35.5L-1.59758e-06 35.5Z" fill="#0C0049" />
    <path d="M6 33.5L6 31.5L8 31.5L8 33.5L6 33.5Z" fill="#0C0049" />
    <path d="M6 51.5L6 49.5L8 49.5L8 51.5L6 51.5Z" fill="#0C0049" />
    <path d="M10 23.5L12 23.5L12 25.5L10 25.5L10 23.5Z" fill="#0C0049" />
    <path d="M-1.66913e-06 41.5L-1.64528e-06 39.5L2 39.5L2 41.5L-1.66913e-06 41.5Z" fill="#0C0049" />
    <path d="M6 39.5L6 37.5L8 37.5L8 39.5L6 39.5Z" fill="#0C0049" />
    <path d="M6 57.5L6 55.5L8 55.5L8 57.5L6 57.5Z" fill="#0C0049" />
    <path d="M8 57.5L8 55.5L10 55.5L10 57.5L8 57.5Z" fill="#0C0049" />
    <path d="M10 57.5L10 55.5L12 55.5L12 57.5L10 57.5Z" fill="#0C0049" />
    <path d="M12 57.5L12 55.5L14 55.5L14 57.5L12 57.5Z" fill="#0C0049" />
    <path d="M14 57.5L14 55.5L16 55.5L16 57.5L14 57.5Z" fill="#0C0049" />
    <path d="M16 57.5L16 55.5L18 55.5L18 57.5L16 57.5Z" fill="#0C0049" />
    <path d="M18 57.5L18 55.5L20 55.5L20 57.5L18 57.5Z" fill="#0C0049" />
    <path d="M20 57.5L20 55.5L22 55.5L22 57.5L20 57.5Z" fill="#0C0049" />
    <path d="M22 57.5L22 55.5L24 55.5L24 57.5L22 57.5Z" fill="#0C0049" />
    <path d="M4 23.5L6 23.5L6 25.5L4 25.5L4 23.5Z" fill="#0C0049" />
    <path d="M-1.74068e-06 47.5L-1.71683e-06 45.5L2 45.5L2 47.5L-1.74068e-06 47.5Z" fill="#0C0049" />
    <path d="M6 45.5L6 43.5L8 43.5L8 45.5L6 45.5Z" fill="#0C0049" />
    <path d="M4 57.5L4 55.5L6 55.5L6 57.5L4 57.5Z" fill="#0C0049" />
    <path d="M2 25.5L4 25.5L4 27.5L2 27.5L2 25.5Z" fill="#0C0049" />
    <path d="M6 25.5L8 25.5L8 27.5L6 27.5L6 25.5Z" fill="#0C0049" />
    <path d="M-1.50218e-06 27.5L2 27.5L2 29.5L-1.52603e-06 29.5L-1.50218e-06 27.5Z" fill="#0C0049" />
    <path d="M24 4.5L26 4.5L26 6.5L24 6.5L24 4.5Z" fill="#0C0049" />
    <path d="M54 4.5L56 4.5L56 6.5L54 6.5L54 4.5Z" fill="#0C0049" />
    <path d="M54 6.5L56 6.5L56 8.5L54 8.5L54 6.5Z" fill="#0C0049" />
    <path d="M54 6.5L56 6.5L56 8.5L54 8.5L54 6.5Z" fill="#0C0049" />
    <path d="M52 6.5L54 6.5L54 8.5L52 8.5L52 6.5Z" fill="#0C0049" />
    <path d="M24 6.5L26 6.5L26 8.5L24 8.5L24 6.5Z" fill="#0C0049" />
    <path d="M26 6.5L28 6.5L28 8.5L26 8.5L26 6.5Z" fill="#0C0049" />
  </svg>
);