import { FC } from 'react';
import classNames from 'classnames';

import styles from './_Element.module.scss';

import type { ComponentProps } from './_Element.types';


export const Element: FC<ComponentProps> = ({
  className,
  type = 'default',
  image,
  name,
  mods
}) => {
  return <>
    <figure className={classNames(
      className,
      styles.root,
      styles[`root_${ type }`],
      mods?.map((mod) => ` ${styles[`root_${ mod }`]}` )
    )}>
      <div className={ styles.image }>
        {
          image && <>
            <img
              src={ image }
              width={ 100 }
              height={ 100 }
              alt={ name }
            />
          </>
        }
      </div>

      {
        (type === 'default' || type === 'reagent') && <>
          <figcaption className={ styles.name }>
            { name }
          </figcaption>
        </>
      }
    </figure>
  </>
};
