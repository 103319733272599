import { FC } from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

import styles from './_Input.module.scss';

import type { ComponentProps } from './_Input.types';


export const Input: FC<ComponentProps> = ({
  className,
  label,
  placeholder,
  type = 'text',
  id,
  mask,
  value,
  onChange,
  error
}) => {
  return <>
    <div className={classNames(styles.root, className, {
      [styles.root_error]: !!error
    })}>
      {label && <label htmlFor={id} className={styles.label}>{label}</label>}

      {mask ? (
        <InputMask
          mask={mask}
          id={id}
          type={type}
          className={styles.input}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      ) : (
        <input
          id={id}
          type={type}
          className={styles.input}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}

      {error && <p className={styles.error}>{error}</p>}

      
    </div>
  </>
};
