import { Levels } from './_levels';

type PromoCodesType = Record<Exclude<Levels, Levels.Fifth>, string | null>;

export const freeBets: PromoCodesType = {
    [Levels.Zero]: '200\u00A0000',
    [Levels.First]: '300\u00A0000',
    [Levels.Second]: '500\u00A0000',
    [Levels.Third]: '800\u00A0000',
    [Levels.Fourth]: '1\u00A0500\u00A0000'
  } as const;