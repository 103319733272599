import { FC } from 'react';
import {
  useAtom,
  useAtomValue
} from 'jotai';
import classNames from 'classnames';
import styles from './_MixingResult.module.scss';

import type { ComponentProps } from './_MixingResult.types';

import {
  currentLevelAtom,
  foundElementsAtom
} from 'atoms';

import { getNumCaptionCase } from 'helpers';

import {
  Modal,
  Button,
  Element,
  FindsCounter
} from 'components';
import { ElementsInLevels, Levels, LevelsCount, MAX_RECIPES } from 'const';
import { freeBets } from 'const/_freeBets';


const getElementsCaption = ( count: number ) => 
  getNumCaptionCase(count, ['элемент', 'элемента', 'элементов']);


export const MixingResult: FC<ComponentProps> = ({
  element1,
  element2,
  mixedElement,
  onClose,
  onAuth,
  onContinue,
  onCloseEsc,
  onNextButtonClick
}) => {
  const [ foundElements, ] = useAtom(foundElementsAtom);
  const currentLevel = useAtomValue(currentLevelAtom);
  const foundElementsCount = foundElements.length;
  const nextLevel: Levels = currentLevel + 1 <= Levels.Fifth ? currentLevel + 1 : Levels.Fifth;
  const restElementsCount = ElementsInLevels[nextLevel] - foundElementsCount;

  return <>
    <Modal
      title={ mixedElement.isNew ? 'Новый элемент!' : 'Уже получен' }
      onClose={ onClose }
      onCloseEsc={onCloseEsc}
    >
      <div className={ styles.root }>
        <div className={ styles.result }>
          <Element
            className={ styles.sourceElement }
            type={ 'small' }
            image={ element1.image }
            name={ element1.name }
            mods={['round']}
          />

          <Element
            className={ styles.resultElement }
            image={ mixedElement.image }
            name={ mixedElement.name }
          />

          <Element
            className={ styles.sourceElement }
            type={ 'small' }
            image={ element2.image }
            name={ element2.name }
            mods={['round']}
          />
        </div>

        <FindsCounter className={ classNames(styles.findsCounter, currentLevel === Levels.Fifth && styles.isComplete ) } />

        <p className={ styles.description } dangerouslySetInnerHTML={{
          __html: mixedElement.description
        }} />

        {
          currentLevel !== Levels.Fifth && (
            <p className={ styles.disclaimer }>
              Осталось открыть победных элементов, чтобы получить шанс выиграть до&nbsp;{freeBets[currentLevel]}&nbsp;₽: <span className={ styles.accentText }>{ restElementsCount }&nbsp;шт</span>. Создай <span className={ styles.accentText }>{ MAX_RECIPES }&nbsp;{ getElementsCaption(MAX_RECIPES) }</span> и&nbsp;прими участие в&nbsp;розыгрыше ретро-формы FONBET x&nbsp;&laquo;Крылья&raquo;
            </p>
          )
        }


        {currentLevel !== Levels.Fifth ? (
          <div className={styles.buttons}>
            {currentLevel > 0 && (
              <Button
                className={ styles.prizesButton}
                modifiers={['accent']}
                onClick={ onAuth }
              >
                Забрать награду
              </Button>
            )}

            <Button
              className={ styles.nextButton }
              onClick={ onNextButtonClick }
            >
              Далее
            </Button>
          </div>
        ): (
          <div className={styles.buttons}>
            <Button
              className={ styles.prizesButton}
              modifiers={['accent']}
              onClick={ onContinue }
            >
              Продолжить
            </Button>
          </div>
        )
        }
      </div>
    </Modal>
  </>
};
