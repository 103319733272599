import {
  FC,
  useEffect,
  useRef
} from 'react';

import styles from './_ProgressBronze.module.scss';

import type { ComponentProps } from './_ProgressBronze.types';
import classNames from 'classnames';


export const ProgressBronze: FC<ComponentProps> = ({
  progress,
  modifiers = [],
}) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.style.setProperty('--progress', `${progress}%`)
    }
  }, [progress])

  return (
    <div 
      className={classNames(
        styles.root, 
        modifiers.map((modifier) => styles[`root_${ modifier }`])
      )} 
      ref={ref}
    >
      <div className={styles.value}></div>
    </div>
  )
};
