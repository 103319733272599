import { Button, Modal } from 'components'
import { FC } from 'react'
import { ComponentProps } from './_GetAward.types'
import styles from './_GetAward.module.scss';
import classNames from 'classnames';


export const GetAward: FC<ComponentProps> = ({
  onClose,
  onAuthBtnClick
}) => {

  return (
    <Modal
      onClose={ onClose }
      topChildren={
        <div className={styles.logo}>
          <img src="/img/combologo.svg" width={130} height={36} alt="Лого Fonbet" />
        </div>
      }
    >
      <div className={styles.root}>
        <h2 className={styles.title}>Награда ждет тебя!</h2>

        <div className={styles.image}>
          <img src="/img/award-mask.png" width={270} height={92} alt="Награда" />
        </div>

        <p className={styles.text}>
          Жми по&nbsp;кнопке, заполняй форму и&nbsp;принимай участие в&nbsp;розыгрыше 10&nbsp;000&nbsp;000 рублей фрибетами и&nbsp;юбилейных ретро-футболок FONBET x&nbsp;Крылья Советов с&nbsp;автографами Тихонова, Каряки и&nbsp;Гаджи Гаджиева!
        </p>

        <Button
          onClick={onAuthBtnClick}
          modifiers={['accent']}
          className={styles.button}
        >
          Забрать награду
        </Button>
      </div>
    </Modal>
  )
}










