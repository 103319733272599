import { Button, Modal } from 'components'
import { FC } from 'react'
import { ComponentProps } from './_Final.types'
import styles from './_Final.module.scss';
import classNames from 'classnames';


export const Final: FC<ComponentProps> = ({
  onClose,
  onFinalClick
}) => {

  return (
    <Modal
      onClose={ onClose }
      modifiers={['without-pattern']}
    >
      <div className={styles.root}>
        <h2 className={styles.title}>Наконец-то!</h2>

        <div className={styles.images}>
          <div className={styles.cup}>
            <img src="/img/cup.png" width={124} height={124} alt="Кубок" />
          </div>

          <div className={classNames(styles.box, styles.box_left_top)}>
            <img src="/img/box1.jpg" width={78} height={78} alt="Футболист" />
          </div>
          
          <div className={classNames(styles.box, styles.box_small, styles.box_left_bottom)}>
            <img src="/img/box2.jpg" width={66} height={66} alt="Футболист" />
          </div>

          <div className={classNames(styles.box, styles.box_small, styles.box_right_bottom)}>
            <img src="/img/box3.jpg" width={66} height={66} alt="Футболист" />
          </div>

          <div className={classNames(styles.box, styles.box_right_top)}>
            <img src="/img/box4.jpg" width={78} height={78} alt="Футболист" />
          </div>
        </div>

        <p className={styles.accent}>
          Элексир бронзы
        </p>

        <p className={styles.text}>
          Спустя столько изысканий вы&nbsp;все-таки смогли восстановить сакральную формулу легендарного триумфа Крыльев 2004&nbsp;года!
        </p>

        <Button
          onClick={onFinalClick}
          modifiers={['accent']}
        >
          Далее
        </Button>
      </div>
    </Modal>
  )
}










