import { useEffect } from 'react';


const setVH = () => {
  document.documentElement.style.setProperty('--vh', `${ window.innerHeight * 0.01 }px`);
};


export const useSetVH = () => {
  useEffect(() => {
    if (window !== undefined) {
      setVH();

      window.addEventListener('resize', setVH);

      return () => window.removeEventListener('resize', setVH);
    }
  }, []);
};
