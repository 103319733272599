import { Levels } from './_levels';

type PromoCodesType = Record<Levels, string | null>;

export const PromoCodes: PromoCodesType = {
  [Levels.Zero]: null,
  [Levels.First]: 'ELIXIR10',
  [Levels.Second]: 'ELIXIR20',
  [Levels.Third]: 'ELIXIR30',
  [Levels.Fourth]: 'ELIXIR40',
  [Levels.Fifth]: 'ELIXIRALL'
} as const;