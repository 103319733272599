import { FC, useEffect, useRef, useState } from 'react'

import styles from './_Loading.module.scss';
import { ProgressBar } from './ProgressBar';
import { ComponentProps } from './_Loading.types';

const MAX_TIMEOUT = 60000;

export const Loading: FC<ComponentProps> = ({
  isLoading
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    timer.current = setTimeout(() => {
      setIsLoaded(true);
    }, MAX_TIMEOUT);

    if (!isLoading) {
      setIsLoaded(true);
      clearTimeout(timer.current);
    }
  }, [isLoading])

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <img src="/img/logo-full.svg" width={238} height={185} alt="Логотип Эликсир Бронзы 2004 " />
      </div>

      <div className={styles.bottom}>
        <p className={styles.description}>Воссоздай формулу легендарного триумфа!</p>

        <ProgressBar isFinish={isLoaded} />
      </div>
    </div>
  )
}