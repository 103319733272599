import { Button } from 'components';

import styles from '../_Onboarding.module.scss';
import { FC } from 'react';
import { ComponentProps } from './_History.types';

export const History: FC<ComponentProps> = ({
  onNextButtonClick
}) => {
  return (
    <>
      <div className={styles.image}>
        <img src="/img/pic-onboarding.jpg" width={270} height={155} alt="Команда" />
      </div>

      <div className={styles.text}>
        <p>20&nbsp;лет назад Крылья Советов завоевали легендарную бронзу и&nbsp;дошли до&nbsp;финала Кубка России. Это была не&nbsp;магия тренера, и&nbsp;не&nbsp;химия игроков, а&nbsp;<strong>настоящая алхимия!</strong></p>
      </div>

      <Button
        className={ styles.nextButton }
        onClick={ onNextButtonClick }
      >
        Далее
      </Button>
    </>
  )
}