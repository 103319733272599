import { FC } from 'react'

import styles from './_Auth.module.scss';
import { Button,  Modal } from 'components';
import { ComponentProps } from './_Auth.types';

export const Auth: FC<ComponentProps> = ({
  onClose,
  onRequestBtnClick,
  onRegistartionBtnClick,
  onBackBtnClick
}) => {

  return (
    <Modal
      onClose={ onClose }
      modifiers={ ['without-pattern'] }
      hasBackBtn
      onBackBtnClick={onBackBtnClick}
    >
      <div className={styles.root}>
        <h3 className={styles.title}>Для участия в&nbsp;розыгрыше <br /> ты&nbsp;должен быть клиентом <strong>FONBET</strong>.</h3>

      
        <div className={styles.actions}>
          <Button
            type='button'
            onClick={onRequestBtnClick}
            modifiers={['accent']}
            className={styles.btn}
          >
            Я уже с FONBET
          </Button>

          <Button
            type='button'
            onClick={onRegistartionBtnClick}
            modifiers={['dark']}
            className={styles.btn}
          >
            Регистрация
          </Button>
        </div>

        <p className={styles.text}>Зарегистрируйся и&nbsp;получи фрибет <strong>15&nbsp;000&nbsp;₽</strong></p>

      </div>
    </Modal>
  )
}
export default Auth