import { FC } from 'react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';

import styles from './_Recipe.module.scss';

import type { ComponentProps } from './_Recipe.types';

import { allElementsAtom } from 'atoms';

import {
  IconPlus,
  IconEqual
} from 'icons';
import { Element } from 'components';


export const Recipe: FC<ComponentProps> = ({
  className,
  type,
  data
}) => {
  const allElements = useAtomValue(allElementsAtom);

  const element1 = allElements.find((element) => element.id === data.element1);
  const element2 = allElements.find((element) => element.id === data.element2);
  const result = allElements.find((element) => element.id === data.result);


  return <>
    <div className={ classNames(className, styles.root, styles[`root_${ type }`]) }>
      <Element
        type={ 'smallest' }
        image={ element1?.image || '' }
        name={ element1?.name || '' }
        mods={['round']}
      />

      <span className={ styles.sign }>
        <IconPlus/>
      </span>

      <Element
        type={ 'smallest' }
        image={ element2?.image || '' }
        name={ element2?.name || '' }
        mods={['round']}
      />

      <span className={ styles.sign }>
        <IconEqual/>
      </span>

      <Element
        type={ 'smallest' }
        image={ result?.image || '' }
        name={ result?.name || '' }
        mods={['light-blue', 'round']}
      />
    </div>
  </>
};
