import { FC, FormEvent, useState } from 'react'

import styles from './_Request.module.scss';
import { Button, Input, Modal } from 'components';
import { ComponentProps } from './_Request.types';
import { RequestPayload, request } from 'api/request';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { currentLevelAtom, currentPromocodeAtom } from 'atoms';
import { Levels, PromoCodes } from 'const';

const TITLES = {
  short: 'Впиши своё имя и\u00A0номер телефона, чтобы участвовать в\u00A0розыгрыше!',
  full: 'Впиши своё имя, номер и\u00A0размер одежды, чтобы участвовать в\u00A0розыгрыше!'
}

enum FieldNames {
  Name = 'name',
  Phone = 'phone',
  Size = 'size'
}

type InputState = {
  value: string;
  error: string | null;
  minLength: number;
  required: boolean;
}

type RadioState = {
  value: boolean;
  invalid: boolean;
}

type FieldsStateType = {
  [key in FieldNames]: InputState
}

const ERROR_TEXTS = {
  [FieldNames.Name]: 'Введите ваше имя',
  [FieldNames.Phone]: 'Введите корректный номер телефона',
  [FieldNames.Size]: 'Введите ваш размер'
}

export const Request: FC<ComponentProps> = ({
  onClose,
  hasWearInput,
  onBackBtnClick,
  onSubmitCallback
}) => {
  const FieldsInitialState: FieldsStateType = {
    [FieldNames.Name]: { value: '', error: null, minLength: 1, required: true },
    [FieldNames.Phone]: { value: '', error: null, minLength: 11, required: true },
    [FieldNames.Size]: { value: '', error: null, minLength: 1, required: !!hasWearInput },
  }

  const [fieldsState, setFieldsState] = useState<FieldsStateType>(FieldsInitialState);

  const [agreement, setAgreement] = useState<RadioState>({ value: true, invalid: false });
  const [isPending, setIsPending] = useState<boolean>(false);

  const currentLevel = useAtomValue(currentLevelAtom);
  const promocode = useAtomValue(currentPromocodeAtom);

  const title = hasWearInput ? TITLES.full : TITLES.short;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    let isFormValid = true;

    Object.keys(fieldsState).forEach((key) => {
      setFieldsState((prev) => ({
        ...prev,
        [key]: {...prev, error: null}
      }))
    })

    setAgreement((prev) => ({...prev, invalid: false}));

    const updatedFieldsState = {...fieldsState};
    
    Object.keys(fieldsState).map((key) => {
      const stateField = key as FieldNames;
      const field = fieldsState[stateField];
      const value = stateField === FieldNames.Phone 
        ? field.value.replace(/\D/g, '') 
        : field.value;


      if (field.required && value.length < field.minLength) {
        isFormValid = false;
        updatedFieldsState[stateField] = {...field, error: ERROR_TEXTS[stateField]}
      }
    });

    setFieldsState(updatedFieldsState);

    if (!agreement.value) {
      setAgreement((prev) => ({...prev, invalid: true}));
      isFormValid = false;
    }

    if (!isFormValid || currentLevel === Levels.Zero || !promocode) {
      return;
    }

    const payload: RequestPayload = {
      level: currentLevel,
      name: fieldsState.name.value,
      phone: +fieldsState.phone.value.replace(/\D/g, ''),
      promo_code: promocode
    }

    try {
      setIsPending(true);
      await request(payload);
      
      onSubmitCallback();
    } catch (e) {
      console.error(e)
    } finally {
      setIsPending(false);
    }
  }

  const updateField = (name: FieldNames, value: string) => {
    setFieldsState((prev) => ({
      ...prev,
      [name]: {...fieldsState[name], value}
    }))
  }

  const onAgreementChange = () => {
    setAgreement((prev) => ({...prev, value: !prev.value}))
  }

  return (
    <Modal
      onClose={ onClose }
      modifiers={ ['without-pattern'] }
      hasBackBtn
      onBackBtnClick={ onBackBtnClick }
    >
      <div className={styles.root}>
        <h3 className={styles.title}>{ title }</h3>

        <form onSubmit={handleSubmit}>
          <div className={styles.fields}>
            <Input
              id='name'
              label='ФИО'
              placeholder='Ваше имя'
              value={fieldsState.name.value}
              onChange={(e) => updateField(FieldNames.Name, e.target.value)}
              error={fieldsState.name.error}
            />

            <Input
              id='phone'
              label='Номер телефона'
              placeholder='+7 9__ ___ __ __'
              mask='+7 999 999 99 99'
              value={fieldsState.phone.value}
              onChange={(e) => updateField(FieldNames.Phone, e.target.value)}
              error={fieldsState.phone.error}
            />

            {hasWearInput && (
              <Input
                id='size'
                label='Размер одежды'
                placeholder='XL'
                value={fieldsState.size.value}
                onChange={(e) => updateField(FieldNames.Size, e.target.value)}
                error={fieldsState.size.error}
              />
            )}
          </div>

          <div className={classNames(styles.agreement, {
            [styles.agreement_invalid]: agreement.invalid
          })}>
            <input
              type='checkbox'
              id='agreement'
              className='visually-hidden'
              checked={agreement.value}
              onChange={onAgreementChange}
            />
            
            <label htmlFor='agreement'>
              Настоящим даю свое <a href='/'>согласие</a> на&nbsp;обработку персональных данных ООО &laquo;ФОНКОР&raquo;, <a href='/'>согласие</a> на&nbsp;обработку сведений об&nbsp;оказанных операторами услугах связи и&nbsp;передачу сведений ООО &laquo;ФОНКОР&raquo;, а&nbsp;также подтверждаю, что ознакомлен&nbsp;с&nbsp;<a href='/'>правилами</a>.
            </label>
          </div>

          <Button
            type='submit'
            onClick={() => {}}
            modifiers={['accent']}
            className={styles.submitBtn}
            disabled={isPending}
          >
            Отправить
          </Button>
        </form>
      </div>
    </Modal>
  )
}
export default Request