import { FC, useEffect, useRef } from 'react';

import styles from './_Registration.module.scss';

import type { ComponentProps } from './_Registration.types';
import { Modal } from 'components';
import { currentPromocodeAtom } from 'atoms';
import { useAtomValue } from 'jotai';


export const Registration: FC<ComponentProps> = ({
  className,
  onBackBtnClick,
  onClose
}) => {
  const containerRef = useRef(null)
  const promoCode = useAtomValue(currentPromocodeAtom)

  useEffect(() => {
    if ((globalThis as any).registrationApi && containerRef.current && promoCode) {
      (globalThis as any).registrationApi.init("registrationContainer")

      setTimeout(() => {
        (globalThis as any).registrationApi.setPromocode(promoCode)
      }, 100)
    }
  })

  return <>
    <Modal  
      onClose={ onClose }
      modifiers={ ['without-pattern'] } 
      hasBackBtn
      onBackBtnClick={ onBackBtnClick }>
      <div className={styles.root}>
        <div ref={containerRef} id="registrationContainer" data-locale="ru" data-button-text="Зарегистрироваться" data-promo-id="promoId" data-hidden-promo-id="true"/>
      </div>
    </Modal>
  </>
};
