import { FC } from 'react';
import { useAtomValue } from 'jotai';

import styles from './_Element.module.scss';

import type { ComponentProps } from './_Element.types';

import { foundRecipesAtom } from 'atoms';

import {
  Modal,
  ScrollContainer,
  Element,
  Recipe
} from 'components';


export const ModalElement: FC<ComponentProps> = ({
  element,
  onClose
}) => {
  const foundRecipes = useAtomValue(foundRecipesAtom);

  const currentElementRecipes = foundRecipes.filter((recipe) => recipe.element1 === element.id || recipe.element2 === element.id);


  return <>
    <Modal
      onClose={ onClose }
      hasCloseButton
      modifiers={['with-blue-block']}
    >
      <div className={ styles.root }>
        <div className={ styles.wrap }>
          <Element
            className={ styles.element }
            image={ element.image }
            name={ element.name }
          />

          <p className={ styles.description } dangerouslySetInnerHTML={{
          __html: element.description 
        }} />

          <h3 className={ styles.recipesTitle }>
            Открытые формулы
          </h3>
        </div>

        <div className={ styles.recipes }>
          {
            currentElementRecipes.length !== 0 ? <>
              <ScrollContainer className={ styles.scrollContainer }>
                <ul className={ styles.recipesList }>
                  {
                    currentElementRecipes.map((recipe) => (
                      <li key={ recipe.id }>
                        <Recipe
                          type={ 'light' }
                          data={ recipe }
                        />
                      </li>
                    ))
                  }
                </ul>
              </ScrollContainer>
            </> : <>
              <p className={ styles.recipesText }>
                { `Попробуй смешать ${ element.name.toUpperCase() } с\u00A0другими элементами, чтобы открыть новые рецепты и\u00A0на\u00A0шаг приблизиться к\u00A0воссозданию формулы эликсира бронзы!` }
              </p>
            </>
          }
        </div>
      </div>
    </Modal>
  </>
};
