import { FC } from 'react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';

import styles from '../_Onboarding.module.scss';

import type  { ComponentProps } from './_Rules.types';

import { allRecipesAtom } from 'atoms';

import {
  Button,
  Recipe
} from 'components';


export const Rules: FC<ComponentProps> = ({
  onStartButtonClick,
  onPrevButtonClick,
  onAwardsBtnClick
}) => {
  const exampleRecipe = useAtomValue(allRecipesAtom)[0];


  return (
    <>
      <Recipe
        className={styles.exampleRecipe}
        type={ 'dark' }
        data={ exampleRecipe }
      />

      <div className={classNames(styles.text, styles.rules)}>
        <p><strong>Выбирайте и&nbsp;смешивайте</strong> различные реагенты, открывайте новые элементы, воссоздайте формулу эликсира той самой бронзы и&nbsp;<strong>зарабатывайте награды от&nbsp;FONBET!</strong></p>
      </div>

      <button type="button" className={styles.awardsButton} onClick={onAwardsBtnClick}>Подробнее о наградах</button>

      <div className={styles.actions}>
        <Button
          className={ styles.action }
          onClick={ onPrevButtonClick }
          modifiers={[ 'dark' ]}
        >
          Назад
        </Button>

        <Button
          className={ styles.action }
          onClick={ onStartButtonClick }
        >
          Начать
        </Button>
      </div>
    </>
  )
}