import { FC, useEffect } from 'react'

import styles from './_Success.module.scss';
import { Modal } from 'components';
import { ComponentProps } from './_Success.types';

const REDIRECT_URL = 'https://www.fon.bet/'
const REDIRECT_TIMEOUT = 5000

export const Success: FC<ComponentProps> = ({
  onClose,
}) => {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = REDIRECT_URL;
    }, REDIRECT_TIMEOUT)
  }, [])


  return (
    <Modal
      onClose={ onClose }
      modifiers={ ['without-pattern'] }
      hasCloseButton
    >
      <div className={styles.root}>
        <h3 className={styles.title}>Поздравляем, алхимик!</h3>
        <p className={styles.text}>Теперь ты&nbsp;принимаешь участие в&nbsp;розыгрыше 3&nbsp;000&nbsp;000 рублей фрибетами и&nbsp;юбилейных ретро-футболок FONBET x&nbsp;&laquo;Крылья&raquo;</p>
        <p className={styles.bottomText}>Следи за&nbsp;новостями в&nbsp;сообществах ФК&nbsp;&laquo;Крылья Советов&raquo; и&nbsp;FONBET!</p>
      </div>
    </Modal>
  )
}
export default Success