import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import {
  useAtomValue,
  useAtom
} from 'jotai';

import styles from './_Game.module.scss';

import type { Element as ElementT } from 'types';
import type { LottieOptions as LottieOptionsT } from 'types';

import {
  allElementsAtom,
  allRecipesAtom,
  foundElementsAtom,
  foundRecipesAtom
} from 'atoms';

import {
  IconFlask,
  IconInfo
} from 'icons';
import {
  ModalOnboarding,
  ModalElement,
  ModalMixingResult,
  ModalAuth,
  ModalRequest,
  ModalSuccess,
  ModalRegistration,
  ModalFinal,
  ModalGetAward,
  ModalAwards
} from 'modals';
import {
  Container,
  Header,
  Element,
  Loading,
  Wizard,
} from 'components';
import { ElementPicker } from './ElementPicker';
import { GameWrappper } from './GameWrappper';
import { getRecipes } from 'api/getRecipes';
import { loadLottie } from 'helpers/_loadLottie';

import Lottie from "lottie-react";
import { INIT_OPENED_ELEMENTS_COUNT, MAX_RECIPES } from 'const';

export const Game: FC = () => {
  const [ elements, setElements ] = useAtom(allElementsAtom);
  const [ allRecipes, setRecipes ] = useAtom(allRecipesAtom);
  const [ foundElements, setFoundElements ] = useAtom(foundElementsAtom);
  const [ foundRecipes, setFoundRecipes ] = useAtom(foundRecipesAtom);

  const reversedFoundElements = useMemo(() => [...foundElements].sort((a, b) => b.id - a.id), [ foundElements ]);

  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ isLoadingScreenShow, setIsLoadingScreenShow ] = useState<boolean>(true);

  const [ isOnboardingModalOpened, setisOnboardingModalOpened ] = useState<boolean>(true);
  const [ isElementModalOpened, setIsElementModalOpened ] = useState<boolean>(false);
  const [ isResultModalOpened, setIsResultModalOpened ] = useState<boolean>(false);
  const [ isAuthModalOpened, setAuthModalOpened ] = useState<boolean>(false);
  const [ isRequestModalOpened, setIsRequestModalOpened ] = useState<boolean>(false);
  const [ isSuccessModalOpened, setIsSuccessModalOpened ] = useState<boolean>(false);
  const [ isRegistartionOpened, setIsRegistartionOpened ] = useState<boolean>(false);
  const [ isFinalModalOpened, setIsFinalModalOpened ] = useState<boolean>(false);
  const [ isGetAwardModalOpened, setIsGetAwardModalOpened ] = useState<boolean>(false);
  const [ isAwardsModalOpened, setIsAwardsModalOpened ] = useState<boolean>(false);

  const [ element1, setElement1 ] = useState<ElementT | null>(null);
  const [ element2, setElement2 ] = useState<ElementT | null>(null);
  const [ currentElement, setCurrentElement ] = useState<ElementT | null>(null);

  const newRecipe = allRecipes.find((recipe) => 
    (element1?.id === recipe.element1 && element2?.id === recipe.element2) ||
    (element1?.id === recipe.element2 && element2?.id === recipe.element1)
  );
  const newElement = elements.find((element) => element.id === newRecipe?.result);

  const lottieTransitionRef = useRef<any>()
  const [ wizard_1, setWizard_1 ] = useState<JSON | null>(null)
  const [ lottieTranstion, setlottieTranstion ] = useState<LottieOptionsT>({
    animationData: null,
    autoplay: false,
    loop: false,
  })
  const [ wizard_2, setWizard_2 ] = useState<JSON | null>(null)
  const [ wizard_3, setWizard_3 ] = useState<JSON | null>(null)
  const [ currentLottie, setCurrentLottie ] = useState<LottieOptionsT>({
    animationData: null,
    autoplay: true,
    loop: true,
  })

  const lottieRef = useRef<any>()
  const [mixButtonLottie, setMixButtonLottie] = useState<JSON | null>(null)

  console.log(element1, element2, newElement)

  const onMixButtonClick = () => {
    setCurrentLottie((prevState) => ({
      ...prevState,
      animationData: wizard_3,
      loop: false, 
      onComplete: () => {
        setIsResultModalOpened(true);

        if (newRecipe && !foundRecipes.includes(newRecipe)) { 
          setFoundRecipes((current) => [ ...current, newRecipe ]);
        }

        if (newElement) {
          const isNew = !foundElements.includes(newElement);

          newElement.isNew = isNew;

          if (isNew) {
            setFoundElements((current) => [ ...current, newElement]);
          }  
        }
      }
    }))
  };

  const onElementInfoButtonClick = ( element: ElementT ) => {
    setCurrentElement(element);
    setIsElementModalOpened(true);
  };

  const onElement1Change = ( element: ElementT ) => {
    setElement1(element);
  };

  const onElement2Change = ( element: ElementT ) => {
    setElement2(element);
  };

  const onElementModalClose = () => {
    setCurrentElement(null);
    setIsElementModalOpened(false);
  };

  const onResultModalClose = () => {
    setElement1(null);
    setElement2(null);
    setCurrentLottie(() => ({
      autoplay: true,
      loop: true,
      animationData: wizard_1,
      onComplete: () => {}
    }))
    
    setIsResultModalOpened(false);
  };

  const onContinue = () => {
    setTimeout(() => {
      onResultModalClose()
    }, 0)
    setIsFinalModalOpened(true)
  }

  const onRequestBtnClick = () => {
    // todo: сделать без костылей
    setTimeout(() => {
      setAuthModalOpened(false);
    }, 0)
    setIsRequestModalOpened(true)
  }

  const onAuthBtnClick = () => {
    // todo: сделать без костылей
    setTimeout(() => {
      setIsResultModalOpened(false)
      setIsGetAwardModalOpened (false)
    }, 0)
    setAuthModalOpened(true);
  }

  const onRequestBackBtnClick = () => {
    // todo: сделать без костылей
    setTimeout(() => {
      setIsRequestModalOpened(false)
    }, 0)
    setAuthModalOpened(true);
  }

  const onRegistartion = () => {
    // todo: сделать без костылей
    setTimeout(() => {
      setAuthModalOpened(false);
    }, 0)
    setIsRegistartionOpened(true)
  }

  const onFinalClick = () => {
    // todo: сделать без костылей
    setTimeout(() => {
      setIsFinalModalOpened(false);
    }, 0)
    setIsGetAwardModalOpened (true)
  }

  const onRegistartionBackBtnClick = () => {
    // todo: сделать без костылей
    setTimeout(() => {
      setIsRegistartionOpened(false)
    }, 0)
    setAuthModalOpened(true);
  }

  const onRequestSubmitCallback = () => {
    // todo: сделать без костылей
    setTimeout(() => {
      setIsRequestModalOpened(false)
    }, 0)
    setIsSuccessModalOpened(true);
  }
  
  const onOnboardingAwardsBtnClick = () => {
    // todo: сделать без костылей
    setTimeout(() => {
      setisOnboardingModalOpened(false)
    }, 0)
    setIsAwardsModalOpened(true);
  }

  const onAuthBackBtnClick = () => {
    // todo: сделать без костылей
    setTimeout(() => {
      setAuthModalOpened(false)
    }, 0)
    setIsResultModalOpened(true);
  }


  const fetchData = useCallback(async () => {
    try {
      const res = await getRecipes();
      const promises = [
        loadLottie('./lotties/wizard-1.json'),
        loadLottie('./lotties/wizard-1-2.json'),
        loadLottie('./lotties/wizard-2.json'),
        loadLottie('./lotties/wizard-3.json'),
        loadLottie('./lotties/mix-button.json')
      ];
  
      const [ lottie1, lottie12, lottie2, lottie3, mixButton ] = await Promise.allSettled(promises);

      if (lottie1.status === 'fulfilled') {
        setWizard_1(lottie1.value)
        setCurrentLottie((prevState) => ({
          ...prevState,
          animationData: lottie1.value
        }))
      }

      if (lottie12.status === 'fulfilled') {
        setlottieTranstion((prevState) => ({
          ...prevState,
          animationData: lottie12.value
        }))
      }

      if (lottie2.status === 'fulfilled') {
        setWizard_2(lottie2.value)
      }

      if (lottie3.status === 'fulfilled') {
        setWizard_3(lottie3.value)
      }

      if (mixButton.status === 'fulfilled') {
        setMixButtonLottie(mixButton.value)
      }

      if (res.result) {
        setElements(res.data.elements);
        setFoundElements(res.data.elements.slice(0, INIT_OPENED_ELEMENTS_COUNT));
        setRecipes(res.data.recipes);
      }
    } catch(e) {
      console.error(e)
    } finally {
      setIsLoading(false);

      setTimeout(() => {
        setIsLoadingScreenShow(false);
      }, 1000)
      
    }
  }, [setElements, setRecipes]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (currentLottie.animationData !== wizard_2 && ((element1 && !element2) || (!element1 && element2))) {
      setCurrentLottie((prevState) => ({
        ...prevState,
        animationData: wizard_2
      }))
      lottieTransitionRef.current.goToAndPlay(0, true)
    }
  }, [element1, element2, wizard_2])

  if (isLoadingScreenShow) {
    return (
      <GameWrappper>
        <Loading isLoading={isLoading} />
      </GameWrappper>
    )
  }


  return <GameWrappper>
    <div className={ styles.root }>
      <Header className={ styles.header } />

      <Wizard
        lottieTransitionRef={lottieTransitionRef}
        currentLottie={currentLottie}
        lottieTransition={lottieTranstion}
      />


      <div className={ styles.wrapper }>
        <Container>
          <div className={ styles.mixer }>
            <div className={ styles.elementWrapper }>
              <Element
                type={ 'reagent' }
                image={ element1?.image || '' }
                name={ 'Реагент 1' }
              />

              {
                element1 && <>
                  <button
                    className={ classNames(styles.elementInfoButton, styles.elementInfoButton_left) }
                    type={ 'button' }
                    onClick={ () => onElementInfoButtonClick(element1) }
                    aria-label={ 'Посмотреть информацию о реагенте 1' }
                  >
                    <IconInfo/>
                  </button>
                </>
              }
            </div>

            <button
              className={classNames(
                styles.mixButton,
                element1 && element2 && newElement && styles.mixButton_isActive,
                element1 && element2 && !newElement && styles.mixButton_isDisabled
              )}
              type={ 'button' }
              onClick={ onMixButtonClick }
            >
              <span
                className={ styles.mixIconWrapper }
                aria-hidden={ true }
              >
              <span className={ styles.mixIconBase }/>
                {mixButtonLottie && (
                  <Lottie
                    lottieRef={lottieRef} 
                    className={styles.lottie} 
                    rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}} 
                    animationData={mixButtonLottie} 
                    autoplay={true} 
                    loop={true} 
                  />
                )}
              </span>

              <span className={ styles.mixCaption }>
                Смешать!
              </span>
            </button>

            <div className={ styles.elementWrapper }>
              <Element
                type={ 'reagent' }
                image={ element2?.image || '' }
                name={ 'Реагент 2' }
              />

              {
                element2 && <>
                  <button
                    className={ classNames(styles.elementInfoButton, styles.elementInfoButton_right) }
                    type={ 'button' }
                    onClick={ () => onElementInfoButtonClick(element2) }
                    aria-label={ 'Посмотреть информацию о реагенте 2' }
                  >
                    <IconInfo/>
                  </button>
                </>
              }
            </div>
          </div>

          <form className={ styles.elements }>
            <ElementPicker
              id={ 1 }
              currentElement={ element1 }
              onChange={ onElement1Change }
              elements={foundElements}
            />

            <ElementPicker
              id={ 2 }
              currentElement={ element2 }
              onChange={ onElement2Change }
              elements={reversedFoundElements}
            />
          </form>
        </Container>
      </div>
    </div>


    {
      isOnboardingModalOpened && <>
        <ModalOnboarding
          onClose={() => setisOnboardingModalOpened(false)}
          onAwardsBtnClick={onOnboardingAwardsBtnClick}
        />
      </>
    }

    {
      isElementModalOpened && currentElement && <>
        <ModalElement
          element={ currentElement }
          onClose={ onElementModalClose }
        />
      </>
    }

    {
      isResultModalOpened && 
      element1 && element2 && newElement && <>
        <ModalMixingResult
          element1={ element1 }
          element2={ element2 }
          mixedElement={ newElement }
          onClose={ () => setIsResultModalOpened(false) }
          onAuth={onAuthBtnClick}
          onContinue={onContinue}
          onCloseEsc={onResultModalClose}
          onNextButtonClick={onResultModalClose}
        />
      </>
    }

    {
      isAuthModalOpened && <>
        <ModalAuth 
          onClose={() => setisOnboardingModalOpened(false)}
          onRequestBtnClick={onRequestBtnClick}
          onRegistartionBtnClick={onRegistartion}
          onBackBtnClick={onAuthBackBtnClick}
        />
      </>
    }

    {
      isRequestModalOpened && <>
        <ModalRequest
          hasWearInput={MAX_RECIPES === foundRecipes.length}
          onClose={() => setisOnboardingModalOpened(false)}
          onBackBtnClick={onRequestBackBtnClick}
          onSubmitCallback={onRequestSubmitCallback}
        />
      </>
    }

    {
      isSuccessModalOpened && <>
        <ModalSuccess
          onClose={() => setIsSuccessModalOpened(false)}
        />
      </>
    }

    {
      isRegistartionOpened && <>
        <ModalRegistration 
          onClose={onRegistartionBackBtnClick}
          onBackBtnClick={onRegistartionBackBtnClick}
        />
      </>
    }

    {
      isFinalModalOpened && <>
        <ModalFinal 
          onClose={() => setIsFinalModalOpened(false)}
          onFinalClick={onFinalClick}
        />
      </>
    }

    {
      isGetAwardModalOpened && <>
        <ModalGetAward
          onClose={() => setIsGetAwardModalOpened(false)}
          onAuthBtnClick={onAuthBtnClick}
        />
      </>
    }

    {
      isAwardsModalOpened && <>
        <ModalAwards
          onClose={() => setIsAwardsModalOpened(false)}
        />
      </>
    }
  </GameWrappper>
};
