import { FC, useState } from 'react'

import styles from './_Onboarding.module.scss';
import {  Modal } from 'components';
import { ComponentProps } from './_Onboarding.types';
import { History } from './History';
import { Rules } from './Rules';

type Step = 'history' | 'rules'

export const Onboarding: FC<ComponentProps> = ({
  onClose,
  onAwardsBtnClick
}) => {
  const [step, setStep] = useState<Step>('history');

  const onNextButtonClick = () => {
    setStep('rules');
  }

  const onPrevButtonClick = () => {
    setStep('history');
  }

  const onStartButtonClick = () => {
    onClose();
  }

  return (
    <Modal
      onClose={ onClose }
    >
      <div className={styles.root}>
        {step === 'history' && (
          <History 
            onNextButtonClick={onNextButtonClick}
          />
        )}

        {step === 'rules' && (
          <Rules 
            onStartButtonClick={onStartButtonClick}
            onPrevButtonClick={onPrevButtonClick}
            onAwardsBtnClick={onAwardsBtnClick}
          />
        )}
      </div>
    </Modal>
  )
}
export default Onboarding