import {
  FC,
  useState
} from 'react';

import styles from './App.module.scss';

import {
  useScaling,
  useSetVH,
  useWindowResize
} from 'hooks';

import { isMobileLandscape } from 'helpers';

import { Game, Rotate } from 'components';



export const App: FC = () => {
  const [ isLandscape, setIsLandscape ] = useState(false);


  useScaling();
  useSetVH();
  useWindowResize(() => setIsLandscape(isMobileLandscape()));


  return <>
    <div className={ styles.root }>
      {
        isLandscape ? <Rotate /> : <Game/>
      }
    </div>

    <div id='modal-root'/>
  </>
};
