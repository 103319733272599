import {
  FC,
  useEffect,
  useMemo,
  useRef
} from 'react';
import classNames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  Swiper,
  SwiperSlide
} from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/scss';
import styles from './_ElementPicker.module.scss';

import type { ComponentProps } from './_ElementPicker.types';

import { foundElementsAtom, foundRecipesAtom } from 'atoms';

import { IconArrow, IconNew } from 'icons';


export const ElementPicker: FC<ComponentProps> = ({
  id,
  currentElement,
  onChange,
  elements
}) => {
  const groupID = `element-${ id }`;

  const setFoundElements = useSetAtom(foundElementsAtom);
  const foundRecipes = useAtomValue(foundRecipesAtom);
  
  const prevButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setFoundElements((current) => current.map((item) => {
      if (item  && item.isNew) {
        item.isNew = !foundRecipes.find((recipe) => (item.id === recipe.element1 || item.id === recipe.element2))
      }
  
      return item
    }))
  }, [foundRecipes, setFoundElements])


  return <>
    <div className={ styles.root }>
      <p className={ styles.caption }>
        { `Выбери ` }

        <span className={ styles.captionName }>
          { `РЕАГЕНТ ${ id }` }
        </span>
      </p>

      <div className={ styles.sliderWrapper }>
        <button
          ref={ prevButtonRef }
          className={ classNames(styles.sliderButton, styles.sliderButton_prev) }
          type={ 'button' }
          aria-label={ 'Предыдущий реагент' }
        >
          <IconArrow/>
        </button>

        <Swiper
          className={ styles.slider }
          modules={[ Navigation ]}
          slidesPerView={ 'auto' }
          spaceBetween={ 4 }
          navigation={{
            prevEl: prevButtonRef.current,
            nextEl: nextButtonRef.current
          }}
        >
          {
            elements.map((element) => {
              const id = `${ groupID }-${ element.id }`;

              return (
                <SwiperSlide
                  key={ id + element.id }
                  className={ styles.element }
                >
                  <input
                    className='visually-hidden'
                    id={ id }
                    type={ 'radio' }
                    name={ groupID }
                    value={ element.id }
                    checked={ currentElement?.id === element.id }
                    onChange={ () => onChange(element) }
                  />

                  <label htmlFor={ id }>
                    <img
                      src={ element.image }
                      width={ 100 }
                      height={ 100 }
                      alt={ element.name }
                    />

                    {element.isNew && (
                      <div className={styles.iconNew}>
                        <IconNew id={id + element.id}/>
                      </div>
                    )}
                  </label>
                </SwiperSlide>
              )
            })
          }
        </Swiper>

        <button
          ref={ nextButtonRef }
          className={ classNames(styles.sliderButton, styles.sliderButton_next) }
          type={ 'button' }
          aria-label={ 'Следующий реагент' }
        >
          <IconArrow/>
        </button>
      </div>
    </div>
  </>
};
