export const getNumCaptionCase = (
  num: number,
  captionForms: string[]
) => {
  const n = Math.abs(num) % 100;
  const n1 = num % 10;

  if (n > 10 && n < 20) {
    return captionForms[2];
  }

  if (n1 > 1 && n1 < 5) {
    return captionForms[1];
  }

  if (n1 === 1) {
    return captionForms[0];
  }

  return captionForms[2];
};
