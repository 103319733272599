import { atom } from 'jotai';

import type { Recipe } from 'types';

import { RECIPES } from 'data/recipes';


// export const allRecipesAtom = atom<Recipe[]>(RECIPES);
export const allRecipesAtom = atom<Recipe[]>([]);

export const foundRecipesAtom = atom<Recipe[]>([]);
