type Params = {
    method?: 'get' | 'post';
    body?: BodyInit | null | undefined;
};


export const makeApiCall = (
    endpoint: string,
    params?: Params
  ) => {
    const {
      method = 'get',
      body
    } = params || {};
  
    return fetch(endpoint, {
      method,
      body
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  