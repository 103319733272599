import { FC, useRef } from 'react';
import classNames from 'classnames';

import styles from './_Wizard.module.scss';

import type { ComponentProps } from './_Wizard.types';

import Lottie from "lottie-react";



export const Wizard: FC<ComponentProps> = ({
  className,
  lottieTransition,
  currentLottie,
  lottieTransitionRef
}) => {
  const lottieRef = useRef<any>()

 
  return <>
    <div className={ classNames(className, styles.component) }>
      {currentLottie.animationData && (
        <Lottie 
          lottieRef={lottieRef} 
          rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}} 
          animationData={currentLottie.animationData} 
          autoplay={currentLottie.autoplay} 
          loop={currentLottie.loop} 
          onComplete={currentLottie.onComplete}/>)}
      {lottieTransition.animationData && (
        <Lottie 
          className={styles.transition} 
          lottieRef={lottieTransitionRef} 
          rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}} 
          animationData={lottieTransition.animationData} 
          autoplay={lottieTransition.autoplay} 
          loop={lottieTransition.loop}/>)}
    </div>
  </>
};
