import { FC, useEffect, useRef, useState } from 'react'

import styles from './_ProgressBar.module.scss';
import type { ComponentProps } from './_ProgressBar.types';

export const ProgressBar: FC<ComponentProps> = ({
  isFinish
}) => {
  const [progress, setProgress] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    const finish = () => {
      setProgress(100);
      clearInterval(timer);
    }

    if (isFinish) {
      finish();
    }

    return () => {
      clearInterval(timer);
    };
  }, [isFinish]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.style.setProperty('--progress', `${progress}%`)
    }
  }, [progress])

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.value} ref={ref}></div>
      </div>
    </div>
  )
}