import { FC } from 'react';
import classNames from 'classnames';

import styles from './_GameWrappper.module.scss';

import type { ComponentProps } from './_GameWrappper.types';

export const GameWrappper: FC<ComponentProps> = ({
  className,
  children
}) => {
  return <>
    <div className={ classNames(className, styles.component) }>
      <div className={styles.phone}>
        <img src='./img/iphone.png' alt="Рамка айфона" />
      </div>
      <div className={styles.content}>
        { children }
      </div>
    </div>
  </>
};