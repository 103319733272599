import { ApiBase } from 'const'
import { makeApiCall } from './_makeApiCall'

export interface RequestPayload {
  level: number,
  name: string,
  phone: number,
  promo_code: string,
}

export const request = async (payload: RequestPayload) => {
  // const data = await makeApiCall(`${ApiBase}/game/phone`, {
  //   method: 'post',
  //   body: JSON.stringify(payload)
  // });
  // return data;
}