import { atom } from 'jotai';

import type { Element } from 'types';

import {
  ELEMENTS,
  FOUND_ELEMENTS_IDS
} from 'data/elements';


// export const allElementsAtom = atom<Element[]>(ELEMENTS);

// export const foundElementsAtom = atom<Element[]>(
//   ELEMENTS.filter((element) => FOUND_ELEMENTS_IDS.includes(element.id))
// );

export const allElementsAtom = atom<Element[]>([]);

export const foundElementsAtom = atom<Element[]>([]);
