import { FC } from 'react';
import classNames from 'classnames';

import styles from './_Rotate.module.scss';

import type { ComponentProps } from './_Rotate.types';
import { IconPhone } from 'icons';


export const Rotate: FC<ComponentProps> = ({
  className
}) => {
  return <>
    <div className={ classNames(className, styles.component) }>
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <IconPhone />
          </div>
          <p>Для корректной работы <br />игры — поверни телефон</p>
        </div>
    </div>
  </>
};
