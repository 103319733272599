import {
  useRef,
  useEffect
} from 'react';


type Handler = () => void;


export const useWindowResize = ( handler: Handler ) => {
  const handlerRef = useRef<Handler>(handler);

  
  useEffect(() => {
    const handleResize = () => {
      if (handlerRef.current) {
        handlerRef.current();
      }
    };

    
    handleResize();

    window.addEventListener('resize', handleResize);
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);
};
