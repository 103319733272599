import { Levels, LevelsByElements } from 'const';

export function findLevel(levels: number[], target: number) {
  let left = 0;
  let right = levels.length - 1;
  let result: Levels.Zero = Levels.Zero;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (levels[mid] <= target) {
      result = levels[mid];
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return LevelsByElements[result];
}