import { FC } from 'react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';

import styles from './_FindsCounter.module.scss';

import type { ComponentProps } from './_FindsCounter.types';

import {
  allElementsAtom,
  foundElementsAtom
} from 'atoms';
import { ProgressBronze } from 'components/ProgressBronze';
import { MAX_RECIPES } from 'const';


export const FindsCounter: FC<ComponentProps> = ({
  className
}) => {
  const allElements = useAtomValue(allElementsAtom);
  const foundElements = useAtomValue(foundElementsAtom);
  const progress = (foundElements.length / allElements.length) * 100;


  return <>
    <div className={ classNames(className, styles.root) }>
      <ProgressBronze
        progress={progress}
        modifiers={['small-gradient']}
      />

      <p className={ styles.counter }>
        { `${ foundElements.length }/${ MAX_RECIPES }` }
      </p>
    </div>
  </>
};
